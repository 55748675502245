import { RouteRecordRaw } from 'vue-router';
import { ROUTE_NAMES } from './route-names';
import EmptyRouterView from 'src/layouts/EmptyRouterView.vue';
import FeatureType = App.Types.FeatureType;

declare module 'vue-router' {

  interface RouteMeta {
    requiresAuth?: boolean,
    requiresFeature?: FeatureType
    requiresTeams?: boolean,
  }
}

const routes: RouteRecordRaw[] = [
  {
    path: '/auth',
    component: () => import('src/layouts/LoginLayout.vue'),
    children: [
      {
        path: 'login',
        name: ROUTE_NAMES.LOGIN,
        props: route => ({ ...route.params, ...route.query }), // converts query strings and params to props,
        component: () => import('pages/auth/LoginPage.vue'),
      },
      {
        path: 'register',
        name: ROUTE_NAMES.REGISTRATION,
        props: route => ({ ...route.params, ...route.query }), // converts query strings and params to props,
        component: () => import('pages/auth/RegistrationPage.vue'),
      },
      {
        path: 'add-to-wait-list',
        name: ROUTE_NAMES.ADD_TO_WAIT_LIST,
        props: route => ({ ...route.params, ...route.query }), // converts query strings and params to props,
        component: () => import('pages/auth/WaitListPage.vue'),
      },
      {
        path: 'forgot-password',
        name: ROUTE_NAMES.FORGOT_PASSWORD,
        props: route => ({ ...route.params, ...route.query }), // converts query strings and params to props,
        component: () => import('pages/auth/PasswordForgot.vue'),
      },
      {
        path: 'reset-password',
        name: ROUTE_NAMES.RESET_PASSWORD,
        props: route => ({ ...route.params, ...route.query }), // converts query strings and params to props,
        component: () => import('pages/auth/PasswordReset.vue'),
      },
    ],
  },
  {
    path: '/site',
    component: () => import('src/layouts/MainOrLoginLayout.vue'),
    children: [
      {
        path: 'impressum',
        name: ROUTE_NAMES.IMPRESSUM,
        component: () => import('pages/site/ImpressumPage.vue'),
      },
      {
        path: 'datenschutz',
        name: ROUTE_NAMES.PRIVACY,
        component: () => import('pages/site/PrivacyPage.vue'),
      },
      {
        path: 'kontakt',
        name: ROUTE_NAMES.CONTACT,
        component: () => import('pages/site/ContactPage.vue'),
      },
      {
        path: 'verify-mail/:token?/:email?/:inviteToken?',
        meta: { requiresAuth: true },
        name: ROUTE_NAMES.VERIFY_EMAIL,
        props: route => ({ ...route.params, ...route.query }), // converts query strings and params to props,
        component: () => import('pages/auth/VerifyEmailPage.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import('src/layouts/MainLayout.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'admin',
        component: EmptyRouterView,
        children: [
          {
            path: 'stats',
            name: ROUTE_NAMES.ADMIN_STATS,
            meta: { requiresFeature: 'admin.stats' },
            component: () => import('src/pages/AdminStatsPage.vue'),
          },
        ],
      },
      {
        path: 'profile',
        component: EmptyRouterView,
        children: [
          {
            path: 'me',
            name: ROUTE_NAMES.MY_PROFILE,
            component: () => import('src/pages/profile/MyProfilePage.vue'),
          },
        ],
      },
      {
        path: '',
        name: ROUTE_NAMES.HOME,
        component: () => import('src/pages/HomePage.vue'),
      },
      {
        path: 'events',
        name: ROUTE_NAMES.EVENTS,
        meta: { requiresTeams: true },
        props: route => ({ ...route.params, ...route.query }), // converts query strings and params to props,
        component: () => import('pages/events/EventListPage.vue'),
      },
      {
        path: 'events/new',
        name: ROUTE_NAMES.EVENTS_CREATE,
        meta: { requiresTeams: true },
        component: () => import('pages/events/EventCreatePage.vue'),
      },
      {
        path: 'events/edit/:eventId',
        name: ROUTE_NAMES.EVENTS_EDIT,
        props: route => ({ ...route.params, ...route.query }), // converts query strings and params to props,
        component: () => import('pages/events/EventEditPage.vue'),
      },
      {
        path: 'events/show/:eventId',
        name: ROUTE_NAMES.EVENTS_SHOW,
        props: route => ({ ...route.params, ...route.query }), // converts query strings and params to props,
        component: () => import('pages/events/EventDetailPage.vue'),
      },
      {
        path: 'events/copy/:eventId',
        name: ROUTE_NAMES.EVENTS_COPY,
        props: route => ({ ...route.params, ...route.query }), // converts query strings and params to props,
        component: () => import('pages/events/EventCopyPage.vue'),
      },
      {
        path: 'teams',
        name: ROUTE_NAMES.TEAMS,
        meta: { requiresTeams: true },
        props: route => ({ ...route.params, ...route.query }), // converts query strings and params to props,
        component: () => import('pages/teams/TeamListPage.vue'),
        children: [],
      },
      {
        path: 'teams/new',
        name: ROUTE_NAMES.TEAMS_CREATE,
        meta: { requiresFeature: 'team.create', requiresTeams: true },
        component: () => import('pages/teams/TeamCreatePage.vue'),
      },
      {
        path: 'team/:teamId',
        name: ROUTE_NAMES.TEAM,
        props: route => ({ ...route.params, ...route.query }), // converts query strings and params to props,
        component: () => import('pages/teams/TeamEditPage.vue'),
      },
      {
        path: 'absences',
        name: ROUTE_NAMES.ABSENCES,
        meta: { requiresTeams: true },
        props: route => ({ ...route.params, ...route.query }), // converts query strings and params to props,
        component: () => import('pages/absence/AbsenceListPage.vue'),
        children: [],
      },
      {
        path: 'absences/new',
        name: ROUTE_NAMES.ABSENCE_CREATE,
        meta: { requiresTeams: true },
        props: route => ({ ...route.params, ...route.query }), // converts query strings and params to props,
        component: () => import('pages/absence/AbsenceCreatePage.vue'),
        children: [],
      },
      {
        path: 'absences/edit/:absenceId',
        name: ROUTE_NAMES.ABSENCE_EDIT,
        props: route => ({ ...route.params, ...route.query }), // converts query strings and params to props,
        component: () => import('pages/absence/AbsenceEditPage.vue'),
      },
      {
        path: 'stats/:teamId?/:tab?/:elementId?',
        name: ROUTE_NAMES.STATS,
        meta: { requiresTeams: true },
        props: route => ({ ...route.params, ...route.query }), // converts query strings and params to props,
        component: () => import('pages/stats/StatsPage.vue'),
        children: [],
      },
      {
        path: 'invite/:token',
        name: ROUTE_NAMES.INVITE,
        props: route => ({ ...route.params, ...route.query }), // converts query strings and params to props,
        component: () => import('pages/InvitePage.vue'),
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    name: ROUTE_NAMES.NOT_FOUND,
    component: () => import('src/pages/ErrorNotFound.vue'),
  },
];

export default routes;

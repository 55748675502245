/* eslint-disable */
/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.config.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import lang from 'quasar/lang/de.js'



import {SessionStorage,Dialog,Notify,Loading,Meta} from 'quasar'



export default { config: {"capacitor":{"backButtonExit":["#/","/login"]},"brand":{"primary":"#444e61","secondary":"#fdefbc","accent":"#fbd758","dark":"#2f3643","dark-page":"#59667f","positive":"#43a047","negative":"#e53935","info":"#4a8ebf","warning":"#eba433"}},lang,plugins: {SessionStorage,Dialog,Notify,Loading,Meta} }


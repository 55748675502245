import { App, URLOpenListenerEvent } from '@capacitor/app';
import { route } from 'quasar/wrappers';
import { createMemoryHistory, createRouter, createWebHashHistory, createWebHistory } from 'vue-router';

import routes from './routes';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function(/* { store, ssrContext } */) {
    const createHistory = process.env.SERVER
        ? createMemoryHistory
        : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory);

    const router = createRouter({
        scrollBehavior: () => ({ left: 0, top: 0 }),
        routes,

        // Leave this as is and make changes in quasar.conf.js instead!
        // quasar.conf.js -> build -> vueRouterMode
        // quasar.conf.js -> build -> publicPath
        history: createHistory(process.env.VUE_ROUTER_BASE),
    });

    router.isReady().then(() => {
        App.addListener('appUrlOpen', function(event: URLOpenListenerEvent) {
            const url = new URL(event.url);
            const path = url.pathname;
            const params = new URLSearchParams(url.search);

            if (path) {
                router.push({
                    path: path,
                    query: Object.fromEntries(params.entries()),
                });
            }
        });
    });

    return router;
});
